<template>
	<div class="account-pages my-5 pt-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card overflow-hidden">
						<div class="bg-primary">
							<div class="text-primary text-center p-4">
								<h5 class="text-white font-size-20" id="title">Reset Password</h5>
								<p class="text-white-50">Set your new password below.</p>
								<router-link tag="a" to="/" class="logo logo-admin">
									<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
								</router-link>
							</div>
						</div>
						<div class="card-body p-4">
							<div class="pt-3 pl-3 pr-3">
								<b-alert
									v-if="isError"
									show
									variant="danger"
									class="mt-4 text-center"
									id="errorAlert"
								>{{errorText}}</b-alert>

								<b-alert
									v-if="!isError && message != null"
									show
									class="mt-4 text-center"
									id="messageAlert"
								>{{ message }}</b-alert>

								<b-form @submit.prevent="resetPassword" class="form-horizontal mt-4" id="form">
									<div v-if="!resetSuccess">
										<b-form-group
											id="input-group-1"
											label="Password"
											label-for="password"
											class="mb-3"
										>
											<b-form-input
												id="password"
												v-model="password"
												type="password"
												placeholder="Enter password"
												:disabled="isResetting"
												:class="{ 'is-invalid': $v.password.$error }"
												@input="$v.password.$touch"
												@blur="$v.password.$touch"
											></b-form-input>
											<div class="invalid-feedback" v-if="!$v.password.required && $v.password.$dirty">Field is required</div>
											<div class="invalid-feedback" v-if="!$v.password.minLength">Must be at least 8 characters</div>
											<div class="invalid-feedback" v-if="!$v.password.maxLength">Maximum 30 characters</div>
										</b-form-group>

										<b-form-group
											id="input-group-2"
											label="Confirm Password"
											label-for="password"
											class="mb-3"
										>
											<b-form-input
												id="passwordConfirm"
												v-model="passwordConfirm"
												type="password"
												placeholder="Enter password"
												:disabled="isResetting"
												:class="{ 'is-invalid': $v.passwordConfirm.$error }"
												@input="$v.passwordConfirm.$touch"
												@blur="$v.passwordConfirm.$touch"
											></b-form-input>
											<div class="invalid-feedback" v-if="!$v.passwordConfirm.required && $v.passwordConfirm.$dirty">Field is required</div>
											<div class="invalid-feedback" v-if="!$v.passwordConfirm.sameAsPassword">Password confirmation doesn't match</div>
											<div class="invalid-feedback" v-if="!$v.passwordConfirm.minLength">Must be at least 8 characters</div>
											<div class="invalid-feedback" v-if="!$v.passwordConfirm.maxLength">Maximum 30 characters</div>
										</b-form-group>

										<div class="form-group row">
											<div class="col-sm-12 text-center">
												<b-button variant="primary" class="w-md" id="resetPasswordBtn" type="submit" :disabled="isResetting">
													<span v-if="!isResetting">Reset Password</span>
													<i v-else class="fas fa-spin fa-circle-notch"></i>
												</b-button>
											</div>
										</div>
									</div> <!-- end v-if="!resetSuccess" -->

									<div class="form-group mt-2 mb-0 row">
										<div class="col-12 text-center">
											<router-link tag="a" to="/login">
												<i class="mdi mdi-login"></i> Login
											</router-link>
										</div>
									</div>
								</b-form>
							</div>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
					<div class="mt-3 text-center">
						<p class="mb-0">
							© {{new Date().getFullYear()}} instaGiv. All Rights Reserved.
						</p>
					</div>
				</div>
				<!-- end col -->
			</div>
			<!-- end row -->
		</div>
	</div>
</template>

<script>
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
	name: 'ResetViaEmail',
	page: {
		title: "Reset Password"
	},
	components: {},
	props: ['resetKey'],
	data() {
		return {
			password: '',
			passwordConfirm: '',
			errorText: null,
			message: null,
			isResetting: false,
			resetSuccess: null,
			isError: false,
		};
	},
	methods: {
		resetPassword() {
			this.errorText = null;
			this.isError = false;
			this.isResetting = true;

			this.$v.$touch()

			if (this.$v.$invalid) {
				this.isResetting = false;
				this.errorText = 'Please make sure all fields are filled in correctly.'
				this.isError = true
				return
			}

			var data = {
				password: this.password,
				passwordConfirm: this.passwordConfirm,
				key: this.resetKey
			}
		
			this.axios.post('charity-user/reset-password', {
					...data
				})
				.then((response) => {
					this.resetSuccess = true;
					this.isResetting = false;
					this.isError = false;
					this.message = response.data.message;
				})
				.catch((error) => {
					this.isResetting = false;
					this.message = null;
					this.errorText = (error.response && error.response.data.error_text) ? error.response.data.error_text : 'An unexpected error occurred. If the problem persists, please contact support.'
					this.isError = true;
					// eslint-disable-next-line
					console.error('Error:', error);
				})
		}
	},
	validations: {
		password: { 
			required,
			minLength: minLength(8),
			maxLength: maxLength(30)
		},
		passwordConfirm: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(30)
		}
	},	
	directives: {
		focus: {
			inserted: (el) => {
				el.focus()
			}
		}
	},
	mounted: function() {
		if (this.resetKey.length !== 30) {
			this.$swal.fire({
				title: 'Reset key missing or invalid.',
				icon: 'error',
				timer: 2000,
				showConfirmButton: false
			}).then(() => {
				this.$router.push('/login')
			}).catch(() => {})
		}
	}
};
</script>